import React, { useEffect, useState } from 'react';
import "./Login.scss"
import { loginModalClose, loginModalOpen, registrationModalOpen, registrationSucceededModalOpen, registrationSucceededModalClose } from '../../features/login/loginSlice';
import { useForm } from 'react-hook-form'
import { userLoginTunk, userRegistrationTunk } from '../../features/login/loginAPI';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';


export const Login = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const form1 = useForm()
  const { 
    loginModal,
    registrationModal,
    loginStatus,
    registrationError,
    loginError,
    registrationSucceeded,
    accessToken
  } = useSelector((state) => state.login);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onSubmit = (data) => {
    dispatch(userRegistrationTunk(data))
  };

  const handleLogin = (data) => {
    dispatch(userLoginTunk(data))
  };

  useEffect(() => {
    if (loginStatus === "succeeded") {
      navigate('/profile')
    }
  }, [loginStatus])

  return (
    <div className='log_reg'>
      {loginModal && (
        <div className='login'>
          <div className='login_close' onClick={() => dispatch(loginModalClose())}>
            <img width={20} height={20} src="/svg/close.svg" alt="" />
          </div>
          <div className='login_title'><p>WELCOME</p></div>
          <div className='login_subTitle'><p>First day here? <span onClick={() => dispatch(registrationModalOpen())}>Register</span></p></div>
          <form className='login_input' onSubmit={form1.handleSubmit(handleLogin)}>
            <div >
              <input
                {...form1.register("email", { required: true })}
                type="email"
                placeholder='EMAIL' />
            </div>
            <div >
              <input
                {...form1.register("password", { required: true })}
                type="password"
                placeholder='PASSWORD' />
            </div>
            <div className='login_button'>
              <button type='submit' >LOGIN</button>
            </div>
          </form>
          {loginError && (
            <small style={{ color: "red" }} >{loginError}</small>

          )}
        </div>
      )}

      {registrationModal && (
        <div className='registration'>
          <div className='registration_close' onClick={() => dispatch(loginModalClose())}>
            <img width={20} height={20} src="/svg/close.svg" alt="" />
          </div>
          <div className='registration_title'><p>WELCOME</p></div>
          <div className='registration_subTitle'><p>Already have an account? <span onClick={() => dispatch(loginModalOpen())}>Login</span></p></div>
          <form className='registration_input' onSubmit={handleSubmit(onSubmit)}>

            <div className='registration_input_item'>
              <input {...register("lastName", { required: true })} placeholder='LAST NAME' />
              {errors.lastName && <small>This field is required</small>}
            </div>

            <div className='registration_input_item'>
              <input {...register("firstName", { required: true })} placeholder='FIRST NAME' />
              {errors.firstName && <small>This field is required</small>}
            </div >

            <div className='registration_input_item'>
              <input type="tel" {...register("phone", { required: true, pattern: /^[0-9]+$/ })} placeholder='PHONE' />
              {errors.phone && <small>Enter a valid phone number</small>}
            </div>

            <div className='registration_input_item'>
              <input type="email" {...register("email", { required: true })} placeholder='EMAIL' />
              {errors.email && <small>Enter a valid email</small>}
            </div>

            <div className='registration_input_item'>
              <select {...register("gender", { required: true })}>
                <option hidden value="">GENDER</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
              {errors.gender && <small>This field is required</small>}
            </div>

            <div className='registration_input_item'>
              <input type="password" {...register("password", { required: true })} placeholder='PASSWORD' />
              {errors.password && <small>This field is required</small>}
            </div>

            {registrationError && (
              <small style={{ color: "red" }}>{registrationError}</small>
            )

            }
            <button type="submit">Registration</button>
          </form>
        </div>
      )}

      {registrationSucceeded && (
        <div className='succeeded_modal'>
          <div className='succeeded_modal_close' onClick={() => dispatch(registrationSucceededModalClose())}>
            <img width={20} height={20} src="/svg/close.svg" alt="" />
          </div>
          <div className='succeeded_modal_login_title'><p>WELCOME</p></div>
          <div className='succeeded_modal_login_subTitle'>
            <p>
              Account successfully created! A confirmation email with an
              activation link has been sent to your email address.
              Please check your inbox and follow the instructions to activate your account.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

