import React, { useState } from "react";
import "./Admin.scss"
import { Products } from "./products/Products";
import { Brand } from "./brand/Brand";
import { Category } from "./category/Category";

export const Admin = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState("products");

  const renderContent = () => {
    switch (selectedMenuItem) {
      case "products":
        return <Products />;
      case "brand":
        return <Brand />;
      case "category":
        return <Category/>;
      case "edit":
        return;
      default:
        return <div>Welcome to your profile</div>;
    }
  };

  return (
    <div className="admin">
      <div className="admin_nav">
        <ul>
          <li className={selectedMenuItem === 'products' ? 'activ' : ''} onClick={() => setSelectedMenuItem('products')}>Products</li>
          <li className={selectedMenuItem === 'brand' ? 'activ' : ''} onClick={() => setSelectedMenuItem('brand')}>Brand</li>
          <li className={selectedMenuItem === 'category' ? 'activ' : ''} onClick={() => setSelectedMenuItem('category')}>Category</li>
        </ul>
      </div>
      <div className="admin_content">
        {renderContent()}
      </div>
    </div>
  )
}