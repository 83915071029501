import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const addBrandTunk = createAsyncThunk(
  'brand/addBrand',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/product/brend/add`, data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({ message: 'An unexpected error occurred', status: error.response?.status || 500 })
    }
  }
);
export const getBrandTunk = createAsyncThunk(
  'brand/getBrand',
  async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/product/brend/get`);
      return response.data;
    } catch (error) {
      if (error) {
        return error.response.data;
      }
      return { message: 'An unexpected error occurred', status: error.response?.status || 500 }
    }
  }
);

export const deleteBrandTunk = createAsyncThunk(
  'brand/deleteBrand',
  async (data) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/product/brend/delete`,data);
      return response.data;
    } catch (error) {
      if (error) {
        return error.response.data;
      }
      return { message: 'An unexpected error occurred', status: error.response?.status || 500 }
    }
  }
);

export const editBrandTunk = createAsyncThunk(
  'brand/editBrand',
  async (data) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/product/brend/edit`,data);
      console.log('response',response);
      
      return response.data;
    } catch (error) {
      if (error) {
        return error.response.data;
      }
      return { message: 'An unexpected error occurred', status: error.response?.status || 500 }
    }
  }
);