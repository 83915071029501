import React, { useState } from 'react';
import styles from "./Message.module.scss"

export const Message = () => {
  const [activeOrder, setActiveOrder] = useState(null);

  const toggleOrder = (index) => {
    setActiveOrder(activeOrder === index ? null : index);
  };
  const messages = [
    {
      firstName: "test",
      lastName: "testyan",
      phone: "098404555",
      email: "test@gmail.com",
      message: "asdfafasdfasdfasdf adsfasdv adsf adsfasd adsf adsfadsf",
    },
    {
      firstName: "test",
      lastName: "testyan",
      phone: "098404555",
      email: "test@gmail.com",
      message: "asdfafasdfasdfasdf adsfasdv adsf adsfasd adsf adsfadsf",
    },
    {
      firstName: "test",
      lastName: "testyan",
      phone: "098404555",
      email: "test@gmail.com",
      message: "asdfafasdfasdfasdf adsfasdv adsf adsfasd adsf adsfadsf",
    },
  ];
  return (
    <div className={styles.accordion}>
      <div className={styles.headerRow}>
        <span>First Name</span>
        <span>Last Name</span>
        <span>Phone Number</span>
        <span>Email</span>
      </div>
      {messages.map((messages, index) => (
        <div key={index} className={`${styles.order} ${activeOrder === index ? styles.active : ""}`}>
          <div
            className={`${styles.row} ${activeOrder === index ? styles.active : ""}`}
            onClick={() => toggleOrder(index)}
          >
            <span>{messages?.firstName}</span>
            <span>{messages?.lastName}</span>
            <span>{messages?.phone}</span>
            <span>{messages?.email}</span>
            <div className={styles.toggleIcon}>
              {activeOrder === index ? "▲" : "▼"}
            </div>
          </div>
          {activeOrder === index && messages.message && (
            <div className={styles.details}>
              <div className={styles.product}>
                <p>Message:</p>
              </div>
              <div>
                <p>{messages?.message}</p>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}