import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { MyRouter } from "./router/MyRouter";
import { Header } from "./pages/components/header/Header";
import { Login } from "./pages/login/Login";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { getBasket } from "./features/products/productsSlice";

function App() {
  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(getBasket())
  },[])

  return (
    <div className="App">
      <BrowserRouter basename="/">
        <Header />
        <MyRouter />
        <Login />
      </BrowserRouter>
    </div>
  );
}

export default App;
