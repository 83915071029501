import React from "react"
import { useParams } from "react-router-dom"
import "./Product.scss"
import { useDispatch, useSelector } from "react-redux"
import { addBasket } from "../../features/products/productsSlice"
import { ProdItems } from "./ProdItems"

export const Product = () => {
  const { name } = useParams()
  const { basket } = useSelector((state) => state.products);

  const product = [ 
    {
      id: 1,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 2,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 3,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 4,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 5,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 6,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 7,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 8,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 9,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 10,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 11,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },

    {
      id: 12,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 13,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 14,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 15,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 16,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 17,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 18,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 19,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 20,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 21,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 22,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 23,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 24,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 25,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },

  ]
  const toUpperCaseText = (text) => {
    return text.toUpperCase(text);
  }

  return (
    <div className="product">
      <div className='product_container'>
        <div className='product_container_title'>
          <p>{toUpperCaseText(name)}</p>
        </div>
        <div className='product_container_list'>
          {
            product.map((elm, index) => {
              return (
                <ProdItems elm={elm} />
              )
            })
          }
        </div>
      </div>
    </div>
  )
}