import React from 'react';
import "./Faq.scss"
import { Trans, useTranslation } from 'react-i18next';

export const Faq = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className='faq_container'>
      <div className='faq'>
        <div className='faq_title' >
          <p>{t('faq.faq')}</p>
        </div>
        <div className='faq_main'>
          <div className='faq_main_left'>
            <div>
              <span>
                {t('faq.title_1')}
              </span>
              <p>
                {t('faq.desc_1')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_2')}
              </span>
              <p>
                {t('faq.desc_2')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_3')}
              </span>
              <p>
                {t('faq.desc_2')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_4')}
              </span>
              <p>
                {t('faq.desc_4')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_5')}
              </span>
              <p>
               <Trans i18nKey="faq.desc_5" components={{ a: <a /> }} />
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_6')}
              </span>
              <p>
                {t('faq.desc_6')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_7')}
              </span>
              <p>
                {t('faq.desc_7')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_8')}
              </span>
              <p>
                {t('faq.desc_8')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_9')}
              </span>
              <p>
                {t('faq.desc_9')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_10')}
              </span>
              <p>
                {t('faq.desc_10')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_11')}
              </span>
              <p>
                {t('faq.desc_11')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_12')}
              </span>
              <p>
                {t('faq.desc_12')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_13')}
              </span>
              <p>
                {t('faq.desc_13')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_14')}
              </span>
              <p>
                {t('faq.desc_14')}
              </p>
            </div>
            <div className='faq_info'>
              <p>Privacy Policy of <a href="/privacy">IdealGift.am</a> </p>
            </div>
          </div>

          <div className='faq_main_right'>
            <div>
              <span>
                {t('faq.title_15')}
              </span>
              <p>
                {t('faq.desc_15')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_16')}
              </span>
              <p>
                {t('faq.desc_16')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_17')}
              </span>
              <p>
                {t('faq.desc_17')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_18')}
              </span>
              <p>
                {t('faq.desc_18')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_19')}
              </span>
              <p>
                {t('faq.desc_19')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_20')}
              </span>
              <p>
                {t('faq.desc_20')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_21')}
              </span>
              <p>
                {t('faq.desc_21')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_22')}
              </span>
              <p>
                {t('faq.desc_22')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_23')}
              </span>
              <p>
                {t('faq.desc_23')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_24')}
              </span>
              <p>
                {t('faq.desc_24')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_25')}
              </span>
              <p>
                {t('faq.desc_25')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_26')}
              </span>
              <p>
                {t('faq.desc_26')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_27')}
              </span>
              <p>
                {t('faq.desc_27')}
              </p>
            </div>
            <div>
              <span>
                {t('faq.title_28')}
              </span>
              <p>
                {t('faq.desc_28')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}