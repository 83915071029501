import React from 'react';
import "./Contact.scss"

export const Contact = () => {

  return (
    <div className='contact_us'>
      <div className='contact'>
        <div className='contact_title' >
          <p>Contact us</p>
        </div>
        <div className='contact_main'>
          <div className='contact_main_left'>
            <div>
              <span>We are always happy to hear from you and are available to assist
                at any time.</span>
              <p>Whether you have questions about our products, services, or
                partnership opportunities, feel free to reach out through any of the
                following channels.
              </p>
            </div>
            <div>
              <span>• Phone:</span>
              <p>You can call us at +374 94 80 59 80 for a quick response to your
                inquiries or for consultations. We are available 24/7 to help you
                choose the perfect gift or answer any corporate gifting requests.
              </p>
              <span>• Email:</span>
              <p>
                Send us an email at idealgift.am@gmail.com, and we’ll be glad to
                answer any questions you may have as soon as possible. Whether
                it’s about orders, partnerships, or collaborations, our team is always
                ready to assist.
              </p>

              <span>• Office Address:</span>
              <p>
                You can find us at Yerevan, Armenia, Komitas 48. Feel free to visit
                our office to discuss your order details or partnership opportunities
                in person.
              </p>

              <span>• Operating Hours:</span>
              <p>
                We operate 24/7, so you can reach out to us at any time that suits
                you best. Our team is always available to provide you with the
                information and support you need.
              </p>

            </div>
            <div className='contact_info'>
              <p>Privacy Policy of <a href="/privacy">IdealGift.am</a> </p>
            </div>
          </div>

          <div className='contact_main_right'>
            <div>
              <span>
                Follow Us on Social Media
              </span>
            </div>
            <div>
              <p>Stay connected with us on social media to be the first to hear about
                new products, promotions, and special offers:</p>
            </div>
            <div>
              <span>• Instagram:</span>
              <p>
                Follow us on <a href="/">@idealgift.am</a>  for inspiring gift ideas and the latest
                product updates.
              </p>
              <span>• Facebook:</span>
              <p>
                Join our community on <a href="/">Facebook</a>  for helpful tips, gift ideas, and
                updates about our company.
              </p>
              <span>• LinkedIn:</span>
              <p>
                For business inquiries and partnerships, visit our <a href="/">LinkedIn</a>  profile to
                learn more about our business and opportunities for collaboration.
              </p>
            </div>

            <div>
              <p>
                Whether you’re looking for the perfect gift, planning a corporate
                event, or seeking partnership opportunities, <a href="/">IdealGift.am</a> is here to
                help. We value every customer and strive to make your experience
                with us as pleasant and efficient as possible.
              </p>
            </div>
            <div>
              <p>
                Get in touch with us today, and we’ll gladly assist you with finding
                the ideal gift or arranging your corporate event.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}