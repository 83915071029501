import React, { useState, useEffect } from 'react';
import './ImageSlider.scss';
import { useNavigate } from "react-router-dom";

const ImageSlider = ({ images = [], interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const navigate = useNavigate()


  const onClick = () => {
    navigate('/catalog')
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setIsSliding(true);
      setTimeout(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
        setIsSliding(false);
      }, 500);
    }, interval);

    return () => clearInterval(timer);
  }, [images.length, interval]);

  return (
    <div className="slider-container">

      <div className={`slider-image-container ${isSliding ? 'slide' : ''}`}>
        <img
          src={images[currentIndex]}
          alt={`slide-${currentIndex}`}
          className="slider-image"
          onClick={() => onClick()}
        />
      </div>
    </div>
  );
};

export default ImageSlider;
