import React, { useEffect } from "react"
import "./PersonalData.scss"
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { getMeTunk } from "../../../features/login/loginAPI";

export const PersonalData = () => {
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const { userInfo } = useSelector((state) => state.login)
  const dispatch = useDispatch()
  const formData = watch();

  useEffect(() => {
    if (userInfo) {
      setValue("firstName", userInfo.firstName || "");
      setValue("lastName", userInfo.lastName || "");
      setValue("phoneNumber", userInfo.phone || "");
      setValue("email", userInfo.email || "");
    }
  }, [userInfo, setValue]);

  useEffect(() => {
    localStorage.setItem('personal_data', JSON.stringify(formData))
  }, [formData])

  return (
    <div className="personal_data">
      <form >
        <div>
          <input {...register("firstName")} placeholder="FIRST NAME" defaultValue={userInfo?.firstName} />
          <input {...register("lastName")} placeholder="LAST NAME" defaultValue={userInfo?.lastName}/>
        </div>
        <div>
          <input {...register("phoneNumber")} placeholder="PHONE NUMBER" defaultValue={userInfo?.phone}/>
        </div>
        <div>
          <input {...register("email")} placeholder="EMAIL"  defaultValue={userInfo?.email}/>
        </div>
      </form>
    </div>
  )

}