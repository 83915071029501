import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { addBrandTunk, deleteBrandTunk, editBrandTunk, getBrandTunk } from "../../../features/brand/brandAPI";
import "./Brand.scss"

export const Brand = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentBrand, setCurrentBrand] = useState(null);
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const form1 = useForm();
  const dispatch = useDispatch()
  const { brandList } = useSelector((state) => state.brand);

  const addBrand = (data) => {
    const formData = new FormData();

    if (data.file && data.file[0]) {
      formData.append("file", data.file[0]);
      formData.append("name", data.name);
    }

    dispatch(addBrandTunk(formData))
    reset()
  }

  const deleteBrand = async (id) => {
    await dispatch(deleteBrandTunk({ id }))
    dispatch(getBrandTunk())
  }

  const openModal = (brand) => {
    setModalOpen(true);
    setCurrentBrand(brand);
    form1.setValue("name", brand.name);
  };

  const closeModal = () => {
    setModalOpen(false);
    setCurrentBrand(null);
  };

  const submitEdit = async (data) => {
    const formData = new FormData();
    console.log('aaaa');
    if (data.file[0]) {
      formData.append("file", data.file[0]);
      formData.append("name", data.name);
      formData.append("id", currentBrand.id);
      await dispatch(editBrandTunk(formData))
      dispatch(getBrandTunk())
      closeModal();
    } else {
      await dispatch(editBrandTunk({ name: data.name, id: currentBrand.id }))
      dispatch(getBrandTunk())
      closeModal();
    }
  };

  useEffect(() => {
    dispatch(getBrandTunk())
  }, [])

  return (
    <div className="brand">
      <div className="brand_control">
        <form onSubmit={handleSubmit(addBrand)}>
          <input {...register("name", { required: true })} type="text" placeholder="Brand Name" />
          <input {...register("file", { required: true })} type="file" />
          <button type="submit">add</button>
        </form>

      </div>

      <div className="brand_table">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Brnad name</th>
              <th>Brnad id</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {brandList?.map((elm) => {
              return (
                <tr key={elm.id}>
                  <td>
                    <img width={90} height={90} src={`${process.env.REACT_APP_BASE_IMG_URL}${elm.images}`} alt="" />
                  </td>
                  <td>
                    {elm.name}
                  </td>
                  <td>
                    {elm.id}
                  </td>
                  <td className="td">
                    <img width={14} height={14} src="./svg/close.svg" onClick={() => deleteBrand(elm.id)} alt="" />
                    <img width={14} height={14} src="./svg/edit.svg" onClick={() => openModal(elm)} alt="" />
                  </td>
                </tr>
              )
            })}
            <tr></tr>
          </tbody>
        </table>
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal_content">
            <h3>Edit Brand</h3>
            <form onSubmit={form1.handleSubmit(submitEdit)}>
              <input
                {...form1.register("name", { required: true })}
                type="text"
                placeholder="Brand Name"
              />
              <input {...form1.register("file")} type="file" />
              <button type="submit">Save</button>
              <button type="button" onClick={closeModal}>
                Cancel
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}