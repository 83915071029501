import React, { useState } from 'react';
import styles from "./OrderHistory.module.scss"

export const OrderHistory = () => {
  const [activeOrder, setActiveOrder] = useState(null);

  const toggleOrder = (index) => {
    setActiveOrder(activeOrder === index ? null : index);
  };
  const orders = [
    {
      id: "#TZ5446",
      date: "22.08.2024",
      status: "Completed",
      paid: "$115 USD",
      details: {
        product: "IDEALGIFT BOX BLACK CODE 23442",
        productUrl: "https://s3-alpha-sig.figma.com/img/c2bf/1b07/5dd59cf4c07b9e66689e0d4741e585c4?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=cgiH3KMVpE2mI2kXTjp5dj--HkY6E07jswreuzqJt-Q5WZ3AJ5vcXtI3HKdhvCw279VidfqwRCHbvFl6hEMOKQq-HTPb-19yN3jsuLKgJ-HzOAfzdyl6zE5UvKpGjR8lbjUOGaALekYrACeLX~r1OA4vKrQKlgTFN6xAQp4bRMBJK7F5vc~pMBkjLPjyAKHCg5SiZJIhuVy5j~F0G2d9hd1r74hXH-uwa37~jm63J~YAFV6D6ozX2XggfjjXeAi5vl6ZzpHAjU63rFr8Kd74FhEfZBBZCF4jzz5NQSwCldJ7FqetDdhfvvfPRcwJO04ngDGO-NCBp3Yaf8Pf~mDXrA__",
        price: "$145 USD",
        quantity: 1,
        contact: "Yerevan City\nKomitas Street 48, Building 4",
        delivery: "Delivery service\nCourier express",
      },
    },
    {
      id: "#TZ5336",
      date: "01.08.2024",
      status: "Completed",
      paid: "$348 USD",
      details: {
        product: "IDEALGIFT BOX BLACK CODE 23442",
        price: "$145 USD",
        quantity: 1,
        contact: "Yerevan City\nKomitas Street 48, Building 4",
        delivery: "Delivery service\nCourier express",
      },
    },
    {
      id: "#TL45441",
      date: "12.06.2024",
      status: "Completed",
      paid: "$35 USD",
      details: {
        product: "IDEALGIFT BOX BLACK CODE 23442",
        price: "$145 USD",
        quantity: 1,
        contact: "Yerevan City\nKomitas Street 48, Building 4",
        delivery: "Delivery service\nCourier express",
      },
    },
  ];
  return (
    <div className={styles.accordion}>
      <div className={styles.headerRow}>
        <span>No Order</span>
        <span>Order Date</span>
        <span>Status</span>
        <span>Paid</span>
      </div>
      {orders.map((order, index) => (
        <div key={index} className={`${styles.order} ${activeOrder === index ? styles.active : ""}`}>
          <div
            className={`${styles.row} ${activeOrder === index ? styles.active : ""}`}
            onClick={() => toggleOrder(index)}
          >
            <span>{order.id}</span>
            <span>{`Purchased ${order.date}`}</span>
            <span>{order.status}</span>
            <span>{order.paid}</span>
            <div className={styles.toggleIcon}>
              {activeOrder === index ? "▲" : "▼"}
            </div>
          </div>
          {activeOrder === index && order.details && (
            <div className={styles.details}>
              <div className={styles.product}>
                <img width={100} height={100} src={order.details.productUrl} alt="" />
                <div className={styles.item}>
                  <p>
                    {order.details.product}
                  </p>
                  <div></div>
                  <p>{order.details.price}</p>
                  <div></div>
                  <p>Quantity: {order.details.quantity}</p>
                </div>
              </div>
              <div className={styles.info}>
                <div>
                  <span>CONTACT DETAILS:</span>
                  <p>{order.details.contact}</p>
                </div>
              </div>
              <div className={styles.delivery}>
                <span>ORDER DETAILS:</span>
                <p>{order.details.delivery}</p>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  )
}