import React from 'react';
import "./About.scss"

export const About = () => {

  return (
    <div className='about_us'>
      <div className='about'>
        <div className='about_title' >
          <p>About us</p>
        </div>
        <div className='about_main'>
          <div className='about_main_left'>
            <div>
              <span>Welcome to <a href="/">IdealGift.am!</a></span>
              <p>We are an online store specializing in unique gifts for every
                occasion. Whether you’re looking for a special gift for a loved one, a
                personalized present for a friend, or thoughtful corporate gifts, we
                have everything you need.
              </p>
            </div>
            <div>
              <p>At <a href="/">IdealGift.am,</a> we take pride in offering carefully curated and
                creative gifts suitable for any theme or event. From birthdays and
                holidays to corporate celebrations and special milestones, our goal
                is to help you find the perfect gift that leaves a lasting impression.
              </p>
            </div>
            <div>
              <span>Our Corporate Gifts</span>
            </div>
            <div>
              <p>In addition to personal gifts, we offer tailored solutions for
                businesses. We understand the importance of showing appreciation
                to employees, clients, and partners. Our corporate gifts are
                designed to reflect your company’s values while making each
                recipient feel valued. Whether it’s for holidays, anniversaries, or
                other corporate events, we help companies strengthen their
                relationships through memorable and thoughtful gifts.
              </p>
            </div>
            <div className='about_info'>
              <p>Privacy Policy of <a href="/privacy">IdealGift.am</a> </p>
            </div>
          </div>

          <div className='about_main_right'>
            <div>
              <span>
                Our Partners and Additional Services
              </span>
            </div>
            <div>
              <p>Beyond gifts, we collaborate with partners who specialize in
                organizing various events. These services are also available
                through us. We provide comprehensive solutions for your events—
                from selecting gifts to organizing the event itself. Whether it’s a
                corporate gathering, a wedding, or a private party, our partners
                ensure the event is executed at the highest level.</p>
            </div>
            <div>
              <span>
                Our Mission
              </span>
            </div>
            <div>
              <p>
                Our mission is simple: to spread joy through gifting. We believe that
                every gift should tell a story, create a memory, and bring people
                closer together. That’s why we work tirelessly to ensure that each
                product in our store is special, high-quality, and delivered with care.
              </p>
            </div>
            <div>
              <p>
                Thank you for choosing <a href="/">IdealGift.am </a>as your destination for all
                things gifting! We are excited to be a part of your special moments
                and help make them unforgettable.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}