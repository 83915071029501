import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const userLoginTunk = createAsyncThunk(
  'user/userLogin',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/login`, data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({ message: 'An unexpected error occurred', status: error.response?.status || 500 })
    }
  }
);

export const userRegistrationTunk = createAsyncThunk(
  'user/userRegistration',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/registration`, data);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({ message: 'An unexpected error occurred', status: error.response?.status || 500 })
    }
  }
);

export const getMeTunk = createAsyncThunk(
  'user/getMe',
  async (id, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/user/me`,id);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue({ message: 'An unexpected error occurred', status: error.response?.status || 500 })
    }
  }
);