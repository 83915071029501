import React, { useEffect, useState } from "react"
import './Order.scss'
import { PersonalData } from "./personal_data/PersonalData";
import { useDispatch, useSelector } from "react-redux";
import { getMeTunk } from "../../features/login/loginAPI";
import { DeliveryAddress } from "./delivery_address/DeliveryAddress";
import { DetailsAndPayment } from "./details_and_payment/DetailsAndPayment";
import { getBasket } from "../../features/products/productsSlice";
import { PriceModal } from "./price_modal/PriceModal";

export const Order = () => {
  const [selectedMenuItem, setSelectedMenuItem] = useState(1);
  const basket = JSON.parse(localStorage.getItem('basket'))
  const { totalPrice,delivery,deliveryPriceList } = useSelector((state) => state.products);

  const id = localStorage.id
  const dispatch = useDispatch()
  const renderLeftContent = () => {
    switch (selectedMenuItem) {
      case 1:
        return <PersonalData />;
      case 2:
        return <DeliveryAddress />;
      case 3:
        return <DetailsAndPayment />;
      default:
        return <div>Welcome to your profile</div>;
    }
  };

  const nextStep = () => {
    setSelectedMenuItem(selectedMenuItem + 1)
    localStorage.setItem('step', selectedMenuItem + 1)
  }

  useEffect(() => {
    const step = localStorage.step
    if (step) {
      setSelectedMenuItem(+step)
    } else {
      localStorage.setItem('step', 1)
    }
    dispatch(getMeTunk({ id }))
    dispatch(getBasket())
  }, [])

  useEffect(() => {
    return () => {
      localStorage.removeItem('totalPrice')
      localStorage.removeItem('step')
      localStorage.removeItem('personal_data')
      localStorage.removeItem('delivery_address')
    };
  }, []);

  return (
    <div className="order">
      <div className="order_container">
        <div className="order_container_left">
          <div className="order_container_left_title">
            <p>Placing an order</p>
          </div>
          <div className="order_container_left_tabs">
            <div>
              <p className={selectedMenuItem === 1 || selectedMenuItem === 2 || selectedMenuItem === 3 ? "activ" : ''}>PERSONAL DATA</p>
            </div>
            <div>
              <hr className={selectedMenuItem === 2 || selectedMenuItem === 3 ? "activ" : ''} />
            </div>
            <div>
              <p className={selectedMenuItem === 2 || selectedMenuItem === 3 ? "activ" : ''}>DELIVERY ADDRESS</p>
            </div>
            <div>
              <hr className={selectedMenuItem === 3 ? "activ" : ''} />
            </div>
            <div>
              <p className={selectedMenuItem === 3 ? "activ" : ''}>DETAILS AND PAYMENT</p>
            </div>
          </div>
          <div className="order_container_left_info">
            <div>
              <img src="/svg/order_req.svg" alt="" />
            </div>
            <div>
              <p>All fields are required</p>
            </div>
          </div>
          <div className="order_container_left_content">
            {renderLeftContent()}
          </div>
          <div className="order_container_left_button">
            <button onClick={() => nextStep()}> {selectedMenuItem === 3 ? 'Pay' : "Next"} </button>
          </div>
        </div>
        <div className="order_container_right">
          <div className="order_container_right_title">
            <p>YOUR ORDER</p>
          </div>
          <div className="order_container_right_order">
            {
              basket?.map(elm => {
                return (
                  <div key={elm.id} className="order_container_right_order_item">
                    <div className="order_container_right_order_img">
                      <img width={100} height={100} src={elm.imgUrl} alt="" />
                    </div>
                    <div className="order_container_right_order_info">
                      <p> {elm?.name} </p>
                      <p>$ {elm?.price} USD</p>
                      <p>Quantity: {elm?.count} </p>
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className="order_container_right_delivery">
            <p>DELIVERY</p>
            <p>$ {delivery} USD</p>
          </div>
          <div className="order_container_right_total">
            <p>TOTAL</p>
            <p>$ {totalPrice} USD</p>
          </div>
        </div>
      </div>
      {deliveryPriceList && (
        <div className="delivery_price_modal">
          <PriceModal/>
        </div>
      )}
    </div>

  )
}