import { createSlice } from '@reduxjs/toolkit';
import { getMeTunk, userLoginTunk, userRegistrationTunk } from './loginAPI';

const initialState = {
  productsList: [],
  loginStatus: 'idle',
  registrationStatus: "idle",
  loginError: null,
  loginModal: false,
  registrationModal: false,
  registrationError: null,
  registrationSucceeded: false,
  accessToken: "" || localStorage.getItem('accessToken'),
  refreshToken: "" || localStorage.getItem('refreshToken'),
  userInfo: {}
}


const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    loginModalOpen: (state, action) => {
      state.loginModal = true
      state.registrationModal = false
    },
    loginModalClose: (state, action) => {

      state.loginModal = false
      state.registrationModal = false
      state.loginError = null
      state.registrationError = null

    },
    registrationModalOpen: (state, action) => {
      state.registrationModal = true
      state.loginModal = false
    },
    registrationModalClose: (state, action) => {
      state.registrationModal = false
      state.registrationError = null
      state.loginError = null
    },
    registrationSucceededModalOpen: (state, action) => {
      state.registrationSucceeded = true
    },
    registrationSucceededModalClose: (state, action) => {
      state.registrationSucceeded = false
    },

    userLogOut: (state, action) => {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('refreshToken')
      state.accessToken = ""
      state.refreshToken = ""
    }

  },
  extraReducers: (builder) => {
    builder
      .addCase(userLoginTunk.pending, (state) => {
        state.loginStatus = 'loading';
      })
      .addCase(userLoginTunk.fulfilled, (state, action) => {
        state.loginStatus = 'succeeded';
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.loginModal = false
        localStorage.setItem('accessToken', action.payload.accessToken)
        localStorage.setItem('refreshToken', action.payload.refreshToken)
        localStorage.setItem('id', action.payload.user.id)
      })
      .addCase(userLoginTunk.rejected, (state, action) => {
        state.loginStatus = 'failed';
        if (action.payload) {
          state.loginError = action.payload.message;
        } else {
          state.loginError = action.error.message || 'Something went wrong';
        }
      });
    builder
      .addCase(userRegistrationTunk.pending, (state) => {
        state.registrationStatus = 'loading';
      })
      .addCase(userRegistrationTunk.fulfilled, (state, action) => {
        state.registrationStatus = 'succeeded';
        state.registrationSucceeded = true
        state.registrationModal = false
      })
      .addCase(userRegistrationTunk.rejected, (state, action) => {
        state.registrationStatus = 'failed';
        if (action.payload) {
          state.registrationError = action.payload.message;
        } else {
          state.registrationError = action.error.message || 'Something went wrong';
        }
      });
    builder
      .addCase(getMeTunk.pending, (state) => {
        state.registrationStatus = 'loading';
      })
      .addCase(getMeTunk.fulfilled, (state, action) => {
        state.registrationStatus = 'succeeded';
        state.userInfo = action.payload
      })
      .addCase(getMeTunk.rejected, (state, action) => {
        state.registrationStatus = 'failed';

      });
  },
});

export const {
  loginModalOpen,
  loginModalClose,
  registrationModalOpen,
  registrationModalClose,
  registrationSucceededModalClose,
  registrationSucceededModalOpen,
  userLogOut
} = loginSlice.actions;
export default loginSlice.reducer;
