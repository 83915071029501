import React, { useEffect, useState } from 'react';
import "./Profile.scss"
import { Edit } from './edit/Edit';
import { useDispatch, useSelector } from 'react-redux';
import { getMeTunk } from '../../features/login/loginAPI';
import { FavoriteBrands } from './favorite-brands/FavoriteBrands';
import { OrderHistory } from './order-history/OrderHistory';
import { Message } from './message/Message';
import { useNavigate } from 'react-router-dom';
import { userLogOut } from '../../features/login/loginSlice';


export const Profile = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const id = localStorage.getItem("id")
  const { userInfo } = useSelector((state) => state.login)
  const [selectedMenuItem, setSelectedMenuItem] = useState("brands");

  useEffect(() => {
    dispatch(getMeTunk({ id }))
  }, [])


  const renderContent = () => {
    switch (selectedMenuItem) {
      case "brands":
        return <FavoriteBrands />;
      case "history":
        return <OrderHistory />;
      case "message":
        return <Message />;
      case "edit":
        return <Edit userInfo={userInfo} />;
      default:
        return <div>Welcome to your profile</div>;
    }
  };

  const getInitials = (firstName, lastName) => {
    const firstInitial = firstName ? firstName[0].toUpperCase() : "";
    const lastInitial = lastName ? lastName[0].toUpperCase() : "";
    return `${firstInitial}${lastInitial}`;
  };

  const logOut = () => {
    dispatch(userLogOut())
    navigate('/')
  }


  return (
    <div className="profile">
      <div className='profile_container'>
        <div className='profile_container_title'>
          <p>MY ACCOUNT</p>
        </div>
        <div className='profile_container_info'>
          <div className='profile_container_info_img'>
            <div className='profile_container_info_img_cirkule'>
              <p>{getInitials(userInfo.firstName, userInfo.lastName)}</p>
            </div>
            <div className='profile_container_info_img_fullName'>
              <h4>{userInfo.firstName} {userInfo.lastName}</h4>
              <p>I've been participating since 2024</p>
            </div>
          </div>
        </div>
        <div className="profile_container_block">
          <div className="profile_container_block_sidebar">
            <div className='profile_container_block_sidebar_left'>
              <div className={selectedMenuItem === 'brands' ? 'active' : ''} onClick={() => setSelectedMenuItem('brands')}>

                <svg width="14" height="14" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.325 6.75411H11.8389M9.58341 15.8266L14.1276 20.9008C14.4337 21.2426 15.0001 21.0261 15.0001 20.5672V5.125C15.0001 4.02043 14.1047 3.125 13.0001 3.125H6.16675C5.06218 3.125 4.16675 4.02043 4.16675 5.125V20.5672C4.16675 21.0261 4.7331 21.2426 5.03922 20.9008L9.58341 15.8266Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
                <p>FAVORITES </p>
              </div>

              <div className={selectedMenuItem === 'history' ? 'active' : ''} onClick={() => setSelectedMenuItem("history")}>
                <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.41349 7.73953L6.39163 7.06558V4.24748M11.7833 6.39163C11.7833 3.41391 9.36934 1 6.39163 1C3.41391 1 1 3.41391 1 6.39163C1 9.36934 3.41391 11.7833 6.39163 11.7833C8.38729 11.7833 10.1297 10.699 11.062 9.08744M10.3042 5.79995L11.6521 7.14786L13 5.79995" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                <p>ORDER HISTORY</p>
              </div>
              {
                (userInfo?.role === "admin" || userInfo?.role === "manager") && (
                  <div className={selectedMenuItem === 'message' ? 'active' : ''} onClick={() => setSelectedMenuItem("message")}>
                    <svg height="14px" width="14px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 217.762 217.762" xmlSpace="preserve">
                      <path d="M108.881,5.334C48.844,5.334,0,45.339,0,94.512c0,28.976,16.84,55.715,45.332,72.454 -3.953,18.48-12.812,31.448-12.909,31.588l-9.685,13.873l16.798-2.153c1.935-0.249,47.001-6.222,79.122-26.942 26.378-1.92,50.877-11.597,69.181-27.364c19.296-16.623,29.923-38.448,29.923-61.455C217.762,45.339,168.918,5.334,108.881,5.334z M115.762,168.489l-2.049,0.117l-1.704,1.145c-18.679,12.548-43.685,19.509-59.416,22.913c3.3-7.377,6.768-17.184,8.499-28.506 l0.809-5.292l-4.741-2.485C30.761,142.547,15,119.42,15,94.512c0-40.901,42.115-74.178,93.881-74.178s93.881,33.276,93.881,74.178 C202.762,133.194,164.547,165.688,115.762,168.489z" stroke="black" strokeWidth="4" />
                    </svg>
                    <p>MESSAGE</p>
                  </div>
                )
              }

              <div className={selectedMenuItem === 'edit' ? 'active' : ''} onClick={() => setSelectedMenuItem("edit")}>
                <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.41349 7.73953L6.39163 7.06558V4.24748M11.7833 6.39163C11.7833 3.41391 9.36934 1 6.39163 1C3.41391 1 1 3.41391 1 6.39163C1 9.36934 3.41391 11.7833 6.39163 11.7833C8.38729 11.7833 10.1297 10.699 11.062 9.08744M10.3042 5.79995L11.6521 7.14786L13 5.79995" stroke="black" strokeLinecap="round" strokeLinejoin="round" />
                </svg>

                <p>EDIT PROFILE</p>
              </div>
            </div>
            <div className='logOut' onClick={logOut}>
              <svg fill="none" height="14" viewBox="0 0 24 24" width="14" xmlns="http://www.w3.org/2000/svg"><path d="M17 16L21 12M21 12L17 8M21 12L7 12M13 16V17C13 18.6569 11.6569 20 10 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H10C11.6569 4 13 5.34315 13 7V8" stroke="#374151" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" /></svg>
              <p>LOG OUT</p>
            </div>
          </div>

          <main className="profile_content">
            {renderContent()}
          </main>
        </div>
      </div>
    </div>
  );
}
