import React, { useEffect, useState } from "react";
import "./SeeProduct.scss"
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addBasket } from "../../../features/products/productsSlice";
import { loginModalOpen } from "../../../features/login/loginSlice";

export const SeeProduct = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [itemCount, setItemCount] = useState(1)
  const { accessToken } = useSelector((state) => state.login);
  const [isChart, setIsChart] = useState(false);

  const plusCount = () => {
    setItemCount(itemCount + 1)

  }
  const minusCount = () => {
    if (itemCount > 1) {
      setItemCount(itemCount - 1)
    }
  }

  const prod = {
    id: 1 ,
    name: "test",
    imgUrl: [
      {
        id: 1,
        src: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
        alt: "Image 1"
      },

      {
        id: 2,
        src: "https://s3-alpha-sig.figma.com/img/78ae/3433/73cca80bc339900fe198d446232429e8?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=enP07~quPigaHodtBVQnRrRUI3kaOdysQ37ana55G0IkbSfQzhWvDURDUBQL-y0-25XXZnJfAOrw7JdI~bTKHGoFDTQjjIczF0ET4LhNixpW~rOYxblkh6t8ku12pd6ftIo7X-yh3RC8CKVPQlYfnQVSPVFGoizn04bD6Z7LeP0KDdmIuzJzCHCwR5uabBUeuHRQBrP1BnW0qO78UMzFLjuGBBqtWoN3jL4m3FNonqzp7px6N4aDBUKPb0Ddpz1FgT9T6kmUL4jvro7RntzwJ5XWu-oj6zDbjoBoyahVk1uvp-owk7wMLxOB2maUb6WNZ5goalL4di7KHseBnMU~pQ__",
        alt: "Image 2"
      },
      {
        id: 3,
        src: "https://s3-alpha-sig.figma.com/img/c2bf/1b07/5dd59cf4c07b9e66689e0d4741e585c4?Expires=1734307200&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=fxY2R~8qjpaQI-rzT~XVlzJoadSx8SwMjngCJig6x4yUgTSHgNWdc13piaOdG4SBuerZE-2zwzwdt63Ggl54chvexrMU-vQ-9zzMhtHSYtzo~c3WxhDJCN~afwEJDipOpoaAgBf-otNLlLk1y7V7msifeKAA8WL8JN9koKZi~Z6V3JLQcX2sUNZuuyk5orAS9qtPySJ5JgAurPy8ip1i7HfXpsBknCBJlt07UOD9Yrm4rULH9wx2TkjP~uWX26S830xPzFV-3AkfialaoysYsPmWEXT3BtfBfHEi4PUXN~n~~N3FmHybdLB7i2LZ77yEtdz-SS4QBs16RnBFWuvEMw__",
        alt: "Image 3"
      },
    ],
    price: "100"
  }
  const product = [
    {
      id: 1,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 2,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 3,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 4,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 5,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 6,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 7,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 8,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 9,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 10,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 11,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },

    {
      id: 12,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 13,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 14,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 15,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 16,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 17,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 18,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 19,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 20,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 21,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 22,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 23,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 24,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },
    {
      id: 25,
      name: "test",
      imgUrl: "https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__",
      price: "100"
    },

  ]

  const addToCart = (item) => {
    
    if (accessToken) {
      setIsChart(true);

      const local = JSON.parse(localStorage.getItem("basket"))
      const data = {
        ...item,
        count: itemCount
      }
      const localData = local?.filter(elm => elm.id === data.id)
      
      if (local) {
        if (!localData.length) {
          dispatch(addBasket(data))
        }
      } else {
        dispatch(addBasket(data))
      }

    } else {
      dispatch(loginModalOpen())
    }
  }

  useEffect(() => {
    const local = localStorage.basket
    if (local) {
      JSON.parse(local).map(item => {
        if (item.id === +id) {
          setIsChart(true)
        }
      })
    }
  }, [])

  const [selectedImage, setSelectedImage] = useState(prod.imgUrl[0].src);
  return (
    <div className="product_item">
      <div className="product_item_container">
        <div className="product_item_container_title">
          <p>SHOP</p>
        </div>
        <div className="product_item_container_box">
          <div className="product_item_container_box_img">
            <div className="product-page">
              <div className="product-page__main-image">
                <img src={selectedImage} alt="Selected" />
              </div>

              <div className="product-page__thumbnails">
                {prod.imgUrl.map((image) => (
                  <img
                    key={image.id}
                    src={image.src}
                    alt={image.alt}
                    className={`product-page__thumbnail ${selectedImage === image.src ? "active" : ""
                      }`}
                    onClick={() => setSelectedImage(image.src)}
                  />
                ))}
              </div>

              <div className="product-page__info">
                <div className="product-page__info_title">
                  <span>IDEALGIFT BOX BLACK</span>
                  <span>Code: 28942</span>
                </div>
                <p>$145 USD</p>
                <div className="product-page__info_quantity">
                  <button onClick={() => minusCount()}>-</button>
                  <span>{itemCount}</span>
                  <button onClick={() => plusCount()}>+</button>
                </div>
                <img onClick={() => addToCart(prod)} src={isChart ? "/svg/cart_activ.svg" : "/svg/cart.svg"} className="product-page__info_add-to-cart" />
              </div>
            </div>
          </div>
          <div className="product_item_container_box_info">
            <p>
              We offer the most advantageous <br />
              conditions for corporate gifts <br />
              A large variety of boxes of any design, <br />
              color, size and taste, and about the quality, <br />
              words are superfluous.
            </p>
          </div>
        </div>
        <div className="product_item_container_recommend">
          {
            product.map(elm => {
              if (elm.id <= 7) {

                return (
                  <img width={150} height={180} src={elm.imgUrl} alt="" />
                )

              }
            })
          }
        </div>
      </div>
    </div>
  )
}