import React from 'react';
import { useForm } from "react-hook-form";
import "./Edit.scss"

export const Edit = ({userInfo}) => {
  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    console.log("Form Data:", data);
  };
  
  return (
    <form className="account-form" onSubmit={handleSubmit(onSubmit)}>
      <section>
        <div className="form-group1">
          <h3>Contact Information</h3>
          <div>
            <div>
              <input
                {...register("firstName", { required: "First Name is required" })}
                placeholder="FIRST NAME"
                type="text"
                defaultValue={userInfo?.firstName}
              />
              {errors.firstName && <small className="error">{errors.firstName.message}</small>}
            </div>
            <div>
              <input
                {...register("lastName", { required: "Last Name is required" })}
                placeholder="LAST NAME"
                type="text"
                defaultValue={userInfo?.lastName}

              />
              {errors.lastName && <small className="error">{errors.lastName.message}</small>}
            </div>

          </div>
        </div>
        <div className="form-group2">
          <div>
            <input
              {...register("phone", { pattern: /^\+?[0-9]*$/ })}
              placeholder="PHONE NUMBER"
              type="number"
              defaultValue={userInfo?.phone}
            />
            {errors.phone && <small className="error">Invalid phone number</small>}
          </div>
          <div>
            <input
              {...register("email", { pattern: /^[^@]+@[^@]+\.[^@]+$/ })}
              placeholder="EMAIL"
              type="email"
              defaultValue={userInfo?.email}

            />
            {errors.email && <small className="error">Invalid email address</small>}

          </div>

        </div>
        <div className="form-group3 gender">
          <input
            id="male"
            {...register("gender", { required: "Gender is required" })}
            type="radio"
            value="male"
            defaultChecked = {userInfo?.gender === "male" ? true:false}
          />
          <label htmlFor="male">Male</label>

          <input
            id="female"
            {...register("gender", { required: "Gender is required" })}
            type="radio"
            value="female"
            defaultChecked={userInfo?.gender === "female" ? true:false}
          />
          <label htmlFor="female">Female</label>
          {errors.gender && <small className="error">{errors.gender.message}</small>}
        </div>
      </section>

      <section>
        <h3>My Addresses</h3>
        <div className="form-group2">
          <input {...register("country")} defaultValue={userInfo?.country} placeholder="CITY" type="text" />
          <input {...register("address")} defaultValue={userInfo?.address} placeholder="ADDRESS" type="text" />
        </div>
        <div className="form-group2">
          <input {...register("apartment")} defaultValue={userInfo?.apartment} placeholder="APARTMENT" type="text" />
          <input {...register("floor")} defaultValue={userInfo?.floor} placeholder="FLOOR" type="text" />
          <input {...register("intercom")} defaultValue={userInfo?.intercom} placeholder="INTERCOM" type="text" />
        </div>
      </section>
      <div className='account-form_save'>
        <button type="submit" >
          SAVE CHANGED
        </button>
      </div>
    </form>
  )
}