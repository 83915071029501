import React from 'react';
import "./Privacy.scss"
import { useTranslation } from 'react-i18next';

export const Privacy = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className='privacy_policy'>
      <div className='privacy'>
        <div className='privacy_title' >
          <p>Privacy Policy</p>
        </div>
        <div className='privacy_main'>
          <div className='privacy_main_left'>
            <div>
              <span>Effective Date: 21.11.2024</span>
            </div>
            <div>
              <p>At <a href="/">IdealGift.am,</a>  we respect your privacy and are committed to
                protecting your personal data. This Privacy Policy explains how we
                collect, use, store, and protect your information when you visit our
                website or use our services.</p>
            </div>
            <div>
              <span>1. Information We Collect</span>
            </div>
            <div>
              <p>We may collect the following information about you:</p>
            </div>
            <div>
              <p> <span>• Personal Data:</span> Name, email address, phone number, delivery
                address, and any other information you provide when registering or
                placing an order.
              </p>
              <p>
                <span>• Non-Personal Data:</span> Information about your device, browser, and
                usage of our website, including your IP address, browser type, and
                the pages you visit.
              </p>
            </div>
            <div>
              <span>2. How We Use Your Information</span>
            </div>
            <div>
              <p>We use your information for the following purposes:</p>
            </div>
            <div>
              <p>• To process and fulfill your orders for gifts and corporate gifts.</p>
              <p>• To communicate with you regarding your orders, including
                confirmations and notifications.</p>
              <p>• To improve our website and services, as well as to develop new
                features and products.</p>
              <p>• To send you marketing materials and offers, if you have opted to
                receive them.</p>
            </div>
            <div>
              <span>3. Disclosure of Information</span>
            </div>
            <div>
              <p>
                We will not sell, trade, or otherwise transfer your personal data to
                third parties without your consent, except where necessary to fulfill
                your order (for example, sharing information with a courier service
                for delivery). We may also share your information with our partners
                who organize various events for the purpose of fulfilling your orders.
              </p>
            </div>
          </div>

          <div className='privacy_main_right'>
            <div>
              <span>4. Protection of Your Information</span>
            </div>
            <div>
              <p>
                We take appropriate technical and organizational measures to
                protect your data from unauthorized access, alteration, disclosure,
                or destruction. However, please remember that no method of
                transmission over the Internet or method ofelectronic
                storage is 100% secure.
              </p>
            </div>
            <div>
              <span>5. Your Rights</span>
            </div>

            <div>
              <p>
                You have the right to request access to your personal data, its
                correction, or deletion. If you wish to exercise these rights, please
                contact us using the information provided below.
              </p>
            </div>
            <div>
              <span>6. Changes to the Privacy Policy</span>
            </div>
            <div>
              <p>
                We may update this Privacy Policy from time to time. If we make
                significant changes, we will notify you by posting the updated
                policy on our website. We recommend periodically reviewing this
                page for any changes.
              </p>
            </div>
            <div>
              <span>7. Contact Information</span>
            </div>
            <div>
              <p>
                If you have any questions about our Privacy Policy, please contact
                us:
              </p>
            </div>
            <div className='privacy_main_right_info' >
              <span>• Phone: +374 94 80 59 80</span>
              <span>• Email: <a href="">idealgift.am@gmail.com</a> </span>
              <span>• Address: Yerevan, Armenia, Komitas 48</span>
              <span>• Instagram: <a href="">@idealgift.am</a> </span>
              <span>• Facebook: <a href="">IdealGift</a> </span>
              <span>• LinkedIn: <a href="">IdealGift</a> </span>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}