import React, { useState } from "react";
import './DetailsAndPayment.scss'
import { useDispatch } from "react-redux";
import { deliveryPriceListOpen } from "../../../features/products/productsSlice";
export const DetailsAndPayment = () => {

  const [deliveryMethod, setDeliveryMethod] = useState("courier");
  const [paymentMethod, setPaymentMethod] = useState("card");
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false);

  const handleChange = (event) => {
    setDeliveryMethod(event.target.value);
  };
  const handlePaymentChange = (event) => {
    setPaymentMethod(event.target.value);
  };

  const openPriceModal = () => {
    dispatch(deliveryPriceListOpen())
  }

  return (
    <div className="details_and_payment">
      <div className="details_and_payment_config">
        <div className="details_and_payment_config_delivery">
          <p>DELIVERY</p>
          <div className="details_and_payment_config_delivery_box">
            <input
              type="radio"
              value="courier"
              id="courier"
              checked={deliveryMethod === "courier"}
              onChange={handleChange}
            />
            <label htmlFor="courier">
              Delivery service courier express
            </label>
          </div>
          <div className="details_and_payment_config_delivery_box">

            <input
              type="radio"
              value="pickup"
              id="pickup"
              checked={deliveryMethod === "pickup"}
              onChange={handleChange}
            />
            <label htmlFor="pickup">
              Self - pickup
            </label>
          </div>
        </div>
        <div className="details_and_payment_config_payment">
          <p>METHOD OF PAYMENT</p>
          <div className="details_and_payment_config_payment_box">
            <input
              type="radio"
              value="card"
              id="card"
              checked={paymentMethod === "card"}
              onChange={handlePaymentChange}
            />
            <label htmlFor="card">

              Payment by card online
            </label>
          </div>
          <div className="details_and_payment_config_payment_box">

            <input
              type="radio"
              value="cash"
              id="cash"
              checked={paymentMethod === "cash"}
              onChange={handlePaymentChange}
            />
            <label htmlFor="cash">
              Cash on Delivery
            </label>
          </div>
        </div>
      </div>
      <div className="details_and_payment_note">
        <p>ORDER NOTE</p>
        <textarea name="" id="" placeholder="MESSAGE"></textarea>
      </div>
      <div className="details_and_payment_info">
        <p>By placing an order, you confirm your agreement
          with the user agreement. Privacy Policy and Offer Agreement.</p>
        <a onClick={() => openPriceModal()} >View Delivery Price List</a>
      </div>
      
    </div>
  )
}