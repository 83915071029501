import React from 'react';
import "./FavoriteBrands.scss"

export const FavoriteBrands = () => {

  const brands = [
    {
      name: 'GURMENIA',
      price: "100",
      url: "https://s3-alpha-sig.figma.com/img/78ae/3433/73cca80bc339900fe198d446232429e8?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=iTVFn8hACtMKiZJv96m~5CXGRJQWWwfQNU6wVPHV9xgTMPByOu2OsTDUX5KmSkLi-3sSbYxjri5IUKlPPW36Hr21Z0PwsE7DdHYt2Wv0frfrwf-UpkiDNxtno7L4Z2ZGZgWkBoT7LWpFjYkUwXMpFHS8mQVC8jEwwvYZLh1q~zYEM2lXnDmUMPlWcpllwVV-yPHP6qR8tasP4CMVN3BiWkwsbO5j65Oe5f2msEn9Dfakmm-esE8W5QJnwB3BNV2FZfHkt-K2Ws8BuPmFMlH9hQq~cNjduxHyoBk8vNjpUnYuuQ38DnmPevmWBQ6WXFWDDz0fBK35kunP1smeBmsl-w__"
    },
    {
      name: 'GURMENIA',
      price: "100",
      url: "https://s3-alpha-sig.figma.com/img/78ae/3433/73cca80bc339900fe198d446232429e8?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=iTVFn8hACtMKiZJv96m~5CXGRJQWWwfQNU6wVPHV9xgTMPByOu2OsTDUX5KmSkLi-3sSbYxjri5IUKlPPW36Hr21Z0PwsE7DdHYt2Wv0frfrwf-UpkiDNxtno7L4Z2ZGZgWkBoT7LWpFjYkUwXMpFHS8mQVC8jEwwvYZLh1q~zYEM2lXnDmUMPlWcpllwVV-yPHP6qR8tasP4CMVN3BiWkwsbO5j65Oe5f2msEn9Dfakmm-esE8W5QJnwB3BNV2FZfHkt-K2Ws8BuPmFMlH9hQq~cNjduxHyoBk8vNjpUnYuuQ38DnmPevmWBQ6WXFWDDz0fBK35kunP1smeBmsl-w__"
    },
    {
      name: 'GURMENIA',
      price: "100",
      url: "https://s3-alpha-sig.figma.com/img/78ae/3433/73cca80bc339900fe198d446232429e8?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=iTVFn8hACtMKiZJv96m~5CXGRJQWWwfQNU6wVPHV9xgTMPByOu2OsTDUX5KmSkLi-3sSbYxjri5IUKlPPW36Hr21Z0PwsE7DdHYt2Wv0frfrwf-UpkiDNxtno7L4Z2ZGZgWkBoT7LWpFjYkUwXMpFHS8mQVC8jEwwvYZLh1q~zYEM2lXnDmUMPlWcpllwVV-yPHP6qR8tasP4CMVN3BiWkwsbO5j65Oe5f2msEn9Dfakmm-esE8W5QJnwB3BNV2FZfHkt-K2Ws8BuPmFMlH9hQq~cNjduxHyoBk8vNjpUnYuuQ38DnmPevmWBQ6WXFWDDz0fBK35kunP1smeBmsl-w__"
    },
    {
      name: 'GURMENIA',
      price: "100",
      url: "https://s3-alpha-sig.figma.com/img/78ae/3433/73cca80bc339900fe198d446232429e8?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=iTVFn8hACtMKiZJv96m~5CXGRJQWWwfQNU6wVPHV9xgTMPByOu2OsTDUX5KmSkLi-3sSbYxjri5IUKlPPW36Hr21Z0PwsE7DdHYt2Wv0frfrwf-UpkiDNxtno7L4Z2ZGZgWkBoT7LWpFjYkUwXMpFHS8mQVC8jEwwvYZLh1q~zYEM2lXnDmUMPlWcpllwVV-yPHP6qR8tasP4CMVN3BiWkwsbO5j65Oe5f2msEn9Dfakmm-esE8W5QJnwB3BNV2FZfHkt-K2Ws8BuPmFMlH9hQq~cNjduxHyoBk8vNjpUnYuuQ38DnmPevmWBQ6WXFWDDz0fBK35kunP1smeBmsl-w__"
    },
    {
      name: 'GURMENIA',
      price: "100",
      url: "https://s3-alpha-sig.figma.com/img/78ae/3433/73cca80bc339900fe198d446232429e8?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=iTVFn8hACtMKiZJv96m~5CXGRJQWWwfQNU6wVPHV9xgTMPByOu2OsTDUX5KmSkLi-3sSbYxjri5IUKlPPW36Hr21Z0PwsE7DdHYt2Wv0frfrwf-UpkiDNxtno7L4Z2ZGZgWkBoT7LWpFjYkUwXMpFHS8mQVC8jEwwvYZLh1q~zYEM2lXnDmUMPlWcpllwVV-yPHP6qR8tasP4CMVN3BiWkwsbO5j65Oe5f2msEn9Dfakmm-esE8W5QJnwB3BNV2FZfHkt-K2Ws8BuPmFMlH9hQq~cNjduxHyoBk8vNjpUnYuuQ38DnmPevmWBQ6WXFWDDz0fBK35kunP1smeBmsl-w__"
    }
  ]

  return (
    <div className='brands'>
      {
        brands.map((elm, index) => {
          return (
            <div key={index} className='brands_item'>
              <div className='brands_item_box'>
                <img width={210} height={210} src={elm?.url} alt="" />
                <svg width="14" height="14" viewBox="0 0 20 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.325 6.75411H11.8389M9.58341 15.8266L14.1276 20.9008C14.4337 21.2426 15.0001 21.0261 15.0001 20.5672V5.125C15.0001 4.02043 14.1047 3.125 13.0001 3.125H6.16675C5.06218 3.125 4.16675 4.02043 4.16675 5.125V20.5672C4.16675 21.0261 4.7331 21.2426 5.03922 20.9008L9.58341 15.8266Z" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
              <p>{elm?.name}</p>
              <p>$ {elm?.price} USD</p>
            </div>
          )
        })
      }
    </div>
  )
}