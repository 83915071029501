import React, { useState } from 'react';
import "./Home.scss"
import ImageSlider from '../components/slider/ImageSlider';
import ImageSlider2 from '../components/slider/imageSlider2';

export const Home = () => {

  const images = [
    '/img/photo1.png',
    '/img/photo2.png',
    '/img/photo1.png',
    '/img/photo2.png',
  ];
  const images2 = [
    '/img/photo2.png',
    '/img/photo1.png',
    '/img/photo2.png',
    '/img/photo1.png',
  ];

  return (
    <div className='home'>

      <div className='home_container'>
        <div className='home_container_left'>
          <div className='home_container_left_title'>
            <p>SPECIAL</p>
            <p>BOX</p>
          </div>
          <div className='home_container_left_img'>
            <ImageSlider images={images} interval={7000} />
          </div>
        </div>
        <div className='home_container_center'>
          <img src="/img/photo3.png" alt="" />
        </div>
        <div className='home_container_right'>
          <div className='home_container_right_img'>
            <ImageSlider2 images={images2} interval={7000} />
          </div>
          <div className='home_container_right_title'>
            <p>CORPORATIVE</p>
            <p>SECTION</p>
          </div>
        </div>
      </div>

    </div>
  );
}

