import React, { useEffect, useState } from "react"
import "./DeliveryAddress.scss"
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import cityData from "../../../assets/city.json";
import { addDeliveryPrice } from "../../../features/products/productsSlice";

export const DeliveryAddress = () => {
  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm();
  const { userInfo } = useSelector((state) => state.login)
  const formData = watch();
  const dispatch = useDispatch()

  useEffect(() => {
    if (userInfo) {
      setValue("city", userInfo.city || "");
      setValue("address", userInfo.address || "");
      setValue("apartment", userInfo.apartment || "");
      setValue("floor", userInfo.floor || "");
      setValue("intercom", userInfo.intercom || "");
    }
  }, [userInfo, setValue]);

  useEffect(() => {
    localStorage.setItem('delivery_address', JSON.stringify(formData))
    const deliveryPrice = cityData.find((city) => city.city === formData?.city)

    if (deliveryPrice) {
      dispatch(addDeliveryPrice(deliveryPrice?.price))
    }

  }, [formData])



  return (
    <div className="delivery_address">
      <form >
        <div>
          <select
            id="city-select"
            value={formData?.city}
            {...register("city")}
          >
            <option value="" disabled >
              SELECT A CITY
            </option>
            {cityData.map((city) => (
              <option key={city.id} value={city.city}>
                {city.city} - {city.price} AMD
              </option>
            ))}
          </select>
        </div>
        <div>
          <input {...register("address")} placeholder="ADRESS" defaultValue={userInfo?.addres} />
        </div>
        <div>
          <input {...register("apartment")} placeholder="APARTMENT" defaultValue={userInfo?.apartment} />
          <input {...register("floor")} placeholder="FLOOR" defaultValue={userInfo?.floor} />
          <input {...register("intercom")} placeholder="INTERCOM" defaultValue={userInfo?.intercom} />
        </div>
      </form>
    </div>
  )
}