import React, { useState, useEffect } from 'react';
import './ImageSlider.scss';
import { useNavigate } from 'react-router-dom';

const ImageSlider2 = ({ images = [], interval = 3000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [nextIndex, setNextIndex] = useState(1);
  const [isSliding, setIsSliding] = useState(false);
  const navigate = useNavigate()

  const onClick = () => {
    navigate('/corporative-section')
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setIsSliding(true);
      setTimeout(() => {
        setCurrentIndex(nextIndex);
        setNextIndex((nextIndex + 1) % images.length);
        setIsSliding(false);
      }, 500);
    }, interval);

    return () => clearInterval(timer);
  }, [nextIndex, images.length, interval]);

  return (
    <div className="slider-container">
      <div
        className={`slider-image-container ${isSliding ? 'slide-right' : ''}`}
        style={{ transform: `translateX(${isSliding ? '100%' : '0%'})` }}
      >
        <img
          onClick={() => onClick()}
          src={images[currentIndex]}
          alt={`slide-${currentIndex}`}
          className="slider-image"
        />
        <img
          onClick={() => onClick()}
          src={images[nextIndex]}
          alt={`slide-${nextIndex}`}
          className="slider-image next-image"
        />
      </div>

    </div>
  );
};

export default ImageSlider2;
