import React, { useState } from "react"
import "./Catalog.scss"
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from 'swiper/modules'
import "swiper/css";
import useWindowSize from "../components/windowSize/useWindowSize";
import { useNavigate } from "react-router-dom";

export const Catalog = () => {
  const [activList, setActivList] = useState(null)
  const [activListName, setActivListName] = useState(null)
  const { width, height } = useWindowSize()
  const navigate = useNavigate()
  const [categoryList, setCategoryList] = useState([
    {
      id: 1,
      name: "MAN",
    },
    {
      id: 2,
      name: "WOMAN",
    },
    {
      id: 3,
      name: "CHILDREN",
    },
    {
      id: 4,
      name: "FLOWERS",
    },
    {
      id: 4,
      name: "CAKE",
    },
    {
      id: 5,
      name: "CHOCOLATE",
    },
    {
      id: 6,
      name: "FESTIVE GIFTS"
    },

  ])

  const images = [
    {
      imgUrl: 'https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__'
    },
    {
      imgUrl: 'https://s3-alpha-sig.figma.com/img/4b78/e642/08349bdd6b6e815fb16d945b5214613b?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jNyKU-KSLtw5aggltKcl3gPct0yrIa3T8~VvP1FZ094SRSyDvCGaH1FZYD2sI3cu8fsyfwVb~-ihEipLPqZTtJqHXZTv3UA1sSEEf8rs91j5qXtn1SKrfB1CbqGJtEyYcH3TiOfyLmufWfy7TAsgJsz0CJuH~iIRMGX5aJx4oHatM8kSlhbw5UyRTZo0tt3oX22Kxyiy918vTbNOSXoEiW7aSZ-hOe7Ve9~2FnE5MH9vr2uIO4GCjQuiGusmITktPFE-B5XZx~h8TJP8xuVf2oUEG5g7nfqZHvV3LyIONdyFGKPkzE2reaoBNZ8mXVngCuCuER~RDq5UlsuCR6Z1jg__ '
    },
    {
      imgUrl: 'https://s3-alpha-sig.figma.com/img/78ae/3433/73cca80bc339900fe198d446232429e8?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=iTVFn8hACtMKiZJv96m~5CXGRJQWWwfQNU6wVPHV9xgTMPByOu2OsTDUX5KmSkLi-3sSbYxjri5IUKlPPW36Hr21Z0PwsE7DdHYt2Wv0frfrwf-UpkiDNxtno7L4Z2ZGZgWkBoT7LWpFjYkUwXMpFHS8mQVC8jEwwvYZLh1q~zYEM2lXnDmUMPlWcpllwVV-yPHP6qR8tasP4CMVN3BiWkwsbO5j65Oe5f2msEn9Dfakmm-esE8W5QJnwB3BNV2FZfHkt-K2Ws8BuPmFMlH9hQq~cNjduxHyoBk8vNjpUnYuuQ38DnmPevmWBQ6WXFWDDz0fBK35kunP1smeBmsl-w__'
    },
    {
      imgUrl: 'https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__'
    },
    {
      imgUrl: 'https://s3-alpha-sig.figma.com/img/c2bf/1b07/5dd59cf4c07b9e66689e0d4741e585c4?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=cgiH3KMVpE2mI2kXTjp5dj--HkY6E07jswreuzqJt-Q5WZ3AJ5vcXtI3HKdhvCw279VidfqwRCHbvFl6hEMOKQq-HTPb-19yN3jsuLKgJ-HzOAfzdyl6zE5UvKpGjR8lbjUOGaALekYrACeLX~r1OA4vKrQKlgTFN6xAQp4bRMBJK7F5vc~pMBkjLPjyAKHCg5SiZJIhuVy5j~F0G2d9hd1r74hXH-uwa37~jm63J~YAFV6D6ozX2XggfjjXeAi5vl6ZzpHAjU63rFr8Kd74FhEfZBBZCF4jzz5NQSwCldJ7FqetDdhfvvfPRcwJO04ngDGO-NCBp3Yaf8Pf~mDXrA__'
    },
    {
      imgUrl: 'https://s3-alpha-sig.figma.com/img/4b78/e642/08349bdd6b6e815fb16d945b5214613b?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=jNyKU-KSLtw5aggltKcl3gPct0yrIa3T8~VvP1FZ094SRSyDvCGaH1FZYD2sI3cu8fsyfwVb~-ihEipLPqZTtJqHXZTv3UA1sSEEf8rs91j5qXtn1SKrfB1CbqGJtEyYcH3TiOfyLmufWfy7TAsgJsz0CJuH~iIRMGX5aJx4oHatM8kSlhbw5UyRTZo0tt3oX22Kxyiy918vTbNOSXoEiW7aSZ-hOe7Ve9~2FnE5MH9vr2uIO4GCjQuiGusmITktPFE-B5XZx~h8TJP8xuVf2oUEG5g7nfqZHvV3LyIONdyFGKPkzE2reaoBNZ8mXVngCuCuER~RDq5UlsuCR6Z1jg__'
    },
    {
      imgUrl: 'https://s3-alpha-sig.figma.com/img/00b5/be43/76e70e0b3e695726df6a98221f2f1374?Expires=1733097600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=gaUJr3kwVtf3HZ3Ub-dBMdYbVlsEFJNhcY0tPkSj0GecmJs~zWcTtW7rZSWdE2FTHK9OJG1OQZ8OyCVyin-5dOxOvGPWOT8-RRBNtWPLSLMJo6RwGN39M2SVodxnWPd~OSQN35gNJmeUceyjR4oJi~DltukNli1bOP-Z9cEAcbi9WFytBXp0mC0S4MyRWkIFglmLU5kKQw9nlsfSOIzYfYVP7dZnEBvOU3y2~BE6SnBTfMa9~v371HGriBloteyKoF3UCleBhzERYcDIXvNzvYt22br~MB0u08-GlYvd4eT4CVDDTfzQiKydHqVjwwE9xkeo0EREwhdWXaj5cSRJKQ__'
    },
  ]

  // const handelCatalog = (index, elm) => {
  //   setActivList(activList === index ? null : index)
  //   setActivListName(elm)
  // }

  const toLowerCaseText = (text) => {
    return text.toLowerCase();
  }

  return (
    <div className="catalog">
      <div className='catalog_container'>
        <div className='catalog_container_title'>
          <p>SHOP</p>
        </div>
        <div className='catalog_container_category'>

          <div className="catalog_container_category_list">
            <ul>
              {
                categoryList?.map((elm, index) => {
                  return (
                    <li key={index} onClick={() => navigate(`/product/${toLowerCaseText(elm.name)}`)} className={activList === index ? "activ" : ''}>
                      {elm.name}
                    </li>
                  )
                })
              }
            </ul>
            <div className="catalog_container_category_list_item">
              <Swiper
                slidesPerView={
                  width < 1200 && width > 750 ? 3 :
                    width < 750 && width > 600 ? 4 :
                      width < 600 && width > 500 ? 3 :
                        width < 500 ? 2 : 5
                }
                spaceBetween={width < 1200 ? 10 : 30}
                centeredSlides={true}
                loop={true}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                speed={3500}
                modules={[Autoplay]}
                className="mySwiper"
              >
                {images.map((elm, index) => (
                  <SwiperSlide key={index}>
                    <img
                      src={elm?.imgUrl}
                      alt={`Image ${index}`}
                      style={{ width: "110px", height: "160px", objectFit: "cover" }}
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div className="catalog_container_category_slider">
            <div style={{ position: "absolute", left: "-200px", top: "30px" }} className="catalog_container_category_slider_track" >
              <img className="catalog_container_category_slider_track_item" width={130} src={images[0].imgUrl} alt="" />
            </div>
            <div style={{ position: "absolute", left: "20px", top: "100px" }} className="catalog_container_category_slider_track" >
              <img className="catalog_container_category_slider_track_item" width={130} src={images[1].imgUrl} alt="" />
            </div>
            <div style={{ position: "absolute", left: "250px", top: "40px" }} className="catalog_container_category_slider_track" >
              <img className="catalog_container_category_slider_track_item" width={130} src={images[2].imgUrl} alt="" />
            </div>
            <div style={{ position: "absolute", left: "480px", top: "-100px" }} className="catalog_container_category_slider_track" >
              <img className="catalog_container_category_slider_track_item" width={130} src={images[3].imgUrl} alt="" />
            </div>
            <div style={{ position: "absolute", right: "450px", top: "-250px" }} className="catalog_container_category_slider_track" >
              <img className="catalog_container_category_slider_track_item" width={130} src={images[4].imgUrl} alt="" />
            </div>
            <div style={{ position: "absolute", right: "220px", top: "-100px" }} className="catalog_container_category_slider_track" >
              <img className="catalog_container_category_slider_track_item" width={130} src={images[5].imgUrl} alt="" />
            </div>
            <div style={{ position: "absolute", right: "0px", top: "0px" }} className="catalog_container_category_slider_track" >
              <img className="catalog_container_category_slider_track_item" width={130} src={images[6].imgUrl} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}